import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

export default function DocumentInfo(props) {
    const { t, i18n } = useTranslation()

    const fileSize = (byte) => {
        const sizeInKb = byte / 1024

        if (sizeInKb > 1024) {
            return (byte / 1024 / 1024).toFixed(2) + " MB"
        } else return sizeInKb.toFixed(2) + " KB"
    }

    return <Modal destroyOnClose {...props} footer={null} title={t("docPage.thong_tin_file")}>
        <div>
            <p className="font-medium">{t("docPage.quyen_truy_cap")}</p>
            {props.data.isPublic ? <p className="text-lime-500">{t("docPage.chia_se_public_note")}</p>:<p>{t("docPage.khong_cong_khai")}</p>}
        </div>
        <div className="mt-2 space-y-2">
            <div className="flex flex-col">
                <span className="text-sm font-medium">{t("file_name")}</span>
                <span className="">{props.data.fileName}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm font-medium">{t("type")}</span>
                <span className="">{props.data.fileType}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm font-medium">{t("size")}</span>
                <span className="">{fileSize(props.data.fileSize)}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm font-medium">{t("create_date")}</span>
                <span className="">{moment(props?.data?.createDate + "z").format("DD/MM/YYYY HH:mm")}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm font-medium">{t("created_by")}</span>
                <span className="">{props.data?.createdByUser?.fullName}</span>
            </div>
        </div>
    </Modal>
}