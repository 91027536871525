import { configureStore } from "@reduxjs/toolkit";
import { ReducersName } from "../utils/constants";
import usersReducer, { setRefreshedToken } from "./features/users/usersSlice";
import { refreshTokenService } from "../services/user.js";
import { setToken } from "../utils/axiosCustomize.js";
import documentSlice from "./features/documentSlice";
import signalSlice from "./features/signalSlice";
import uploaderSlice from "./features/uploader/uploader.slice.js"

const middlewares = [];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: {
    [ReducersName.Users]: usersReducer,
    document: documentSlice,
    signal: signalSlice,
    uploader: uploaderSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export const refreshTokenAndRetryRequest = async () => {
  const newToken = await refreshTokenService();
  setToken(newToken);
  store.dispatch(setRefreshedToken(newToken));
  window.location.reload();
};
