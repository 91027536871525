const LANGS = {
    VN: "vi-VN",
    TL: "th-TH",
    TQ: "zh-CN"
}

export const LANGS_MAP = {
    "vi": "vi-VN",
    "th": "th-TH",
    "zh": "zh-CN"
}

export default LANGS