import { useEffect, useState } from "react";
import { getFileDetail, getFilePublic } from "../services/document";
import { useNavigate, useParams } from "react-router";
export default function SharingFile() {
  const { fileId } = useParams();
  const [fileData, setFileData] = useState(false);
  const nav = useNavigate();

  const load = async () => {
    const { success, data, ...args } = await getFilePublic(fileId);
    if (success) {
      setFileData(data);
    } else {
      nav("/not-found");
    }
  };

  useEffect(() => {
    load();
  }, [fileId]);

  return (
    <div className="flex items-center flex-col h-screen px-6">
      <div className="w-full px-6 py-3">
        <p className="text-xl font-medium">
          {fileData && fileData.fileName_VN}
        </p>
      </div>

      {fileData &&
        (fileData.fileType === "video" ? (
          <video
            className="flex-grow h-full rounded-xl"
            controls
            src={fileData.filePath}
          />
        ) : (
          <img src={fileData.filePath} className="max-w-full rounded-xl" />
        ))}
    </div>
  );
}
