import React from "react";
import {
  Button,
  Card,
  Col,
  Image,
  List,
  Modal,
  Row,
  Skeleton,
  Typography,
  message,
} from "antd";
import { CiEdit, CiTrash, CiRead } from "react-icons/ci";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { deleteUser, getMe } from "../../app/features/users/thunks";
import avatarDefault from "../../assets/images/avatar-default.png";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const UsersList = ({
  actionUsers,
  users,
  loadMore,
  updateUser,
  loadingData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );

  const info = (item) => {
    Modal.info({
      icon: null,
      content: (
        <div style={{ textAlign: "center" }}>
          <div className="flex justify-center items-center">
            <InfoCircleOutlined className="!text-[#ff9900] text-[1.2rem] mr-2" />
            <Typography.Title level={5} className="text-center !my-1">
              {t("infor_employee")}
            </Typography.Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col
              span={24}
              className="min-h-[170px] w-full overflow-hidden my-2 rounded-md"
            >
              <div className="h-full w-full flex items-center justify-center">
                <div className="bg-[#BFBFBF] rounded-full overflow-hidden h-[170px] w-[170px] flex items-center justify-center">
                  <Image
                    className="bg-[#BFBFBF] rounded-full object-cover "
                    width={170}
                    height={170}
                    src={item.avataUrl || avatarDefault}
                    alt="avatar"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row gutter={[16, 16]} className="rounded-md">
            <Col span={12} className="w-full overflow-hidden my-2">
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("employee_name")}:
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("user_name")}:
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">{t("site")}:</p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">{t("department")}:</p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("number_phone")}:
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("account_status")}:
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("email_address")}:
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("position_admin")}:
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify={"end"}>
                <Col>
                  <p className="text-[0.9rem] font-semibold my-1">
                    {t("position_leader")}:
                  </p>
                </Col>
              </Row>
            </Col>
            <Col span={12} className=" w-full overflow-hidden my-2">
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item.fullName || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item.userName || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item?.site?.siteName || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item?.department?.departmentName || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1">
                    {item.phoneNumber || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item.isBlock ? t("blocked") : t("activating")}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item.userEmail || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item.isSuperUser ? `(${t("yes")})` : `(${t("no")})` || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col className="!pl-0">
                  <p className="text-[0.9rem] my-1 max-w-[170px] truncate">
                    {item.isLeader ? `(${t("yes")})` : `(${t("no")})` || `(${t("not_yet")})`}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ),
      okButtonProps: {
        style: {
          backgroundColor: "#ff9900",
          borderColor: "#ff9900",
          width: "30%",
          margin: "0 auto",
        },
      },
      onOk() {},
    });
  };

  const onDeleteUser = async (item) => {
    const resGetMe = await dispatch(getMe());
    const loggedInUserId = resGetMe.payload.data.id;
    const userIdToDelete = item.id;
    if (userIdToDelete == loggedInUserId) {
      message.error(t("not_delete_myself"));
    } else {
      const resDel = await dispatch(deleteUser({ id: userIdToDelete }));
      if (resDel.payload.success) {
        message.success(t("delete_employee_success"));
        actionUsers({ id: userIdToDelete }, "delete");
      } else {
        for (let error in resDel.payload.errors) {
          resDel.payload.errors[error].forEach((err) => {
            message.error(err);
          });
        }
      }
    }
  };

  const deleteModal = (item) => {
    Modal.confirm({
      className: "confirm-btn",
      title: t("confirm_delete"),
      content: t("confirm_delete_employee"),
      okText: t("delete"),
      cancelText: t("cancel"),
      cancelButtonProps: {
        danger: true,
        type: "primary",
      },
      onOk: () => onDeleteUser(item),
    });
  };

  const onUpdateUser = (record) => {
    updateUser(record);
  };

  return (
    <List
      className="mt-4"
      grid={{
        gutter: 16,
        column: 6,
      }}
      loading={loadingData}
      loadMore={loadMore}
      dataSource={users}
      renderItem={(item) => (
        <List.Item>
          <Card
            style={{ width: "100%", textAlign: "center" }}
            className={`${
              item.loading ? "border-[#f0f0f0]" : "border-[#ff9900]"
            }`}
          >
            {item.loading ? (
              <Skeleton.Avatar shape="circle" size={80} active />
            ) : (
              <div className="h-full w-full flex items-center justify-center">
                <div className="bg-[#BFBFBF] rounded-full overflow-hidden h-[75px] w-[75px] flex items-center justify-center border-[2px] border-primary border-solid">
                  <Image
                    preview={false}
                    className="bg-[#BFBFBF] rounded-full object-cover"
                    width={75}
                    height={75}
                    src={item.avataUrl || avatarDefault}
                    alt="avatar"
                  />
                </div>
              </div>
            )}
            <Skeleton
              active
              paragraph={{ rows: 1, width: " 50%" }}
              title={false}
              className="user-card-skeleton title"
              style={{ margin: "0 !important" }}
              loading={item.loading}
            >
              <Typography.Title level={5} className="mt-4 truncate">
                {item.fullName || "Không tên"}
              </Typography.Title>
            </Skeleton>

            <Skeleton
              active
              paragraph={{ rows: 1, width: " 100%" }}
              title={false}
              loading={item.loading}
            >
              <p className="!truncate text-[#5f5f5f] my-1">
                {item?.site?.siteName || `(${t("havent_site")})`}
              </p>
            </Skeleton>

            <Skeleton
              active
              paragraph={{ rows: 1, width: " 100%" }}
              title={false}
              loading={item.loading}
            >
              <p className="!truncate text-[#5f5f5f] my-1">
                {item?.department?.departmentName || `(${t("havent_department")})`}
              </p>
            </Skeleton>

            <div className="w-full grid grid-cols-3 gap-2 px-9 mt-4">
              <Skeleton
                active
                paragraph={{ rows: 1, width: "100%" }}
                title={false}
                loading={item.loading}
              >
                <Button
                  onClick={() => info(item)}
                  size="middle"
                  className="list-btn-user !border-[#ff9900] text-[#000] hover:!bg-[#ff9900] hover:!text-white hover:!border-[#ff9900]"
                >
                  <CiRead className="text-[1.3rem]" />
                </Button>
              </Skeleton>
              <Skeleton
                active
                paragraph={{ rows: 1, width: " 100%" }}
                title={false}
                loading={item.loading}
              >
                <Button
                  disabled={item.id !== userDetails.id && item.isSuperUser}
                  type="primary"
                  onClick={() => onUpdateUser(item)}
                  size="middle"
                  className={`list-btn-user`}
                >
                  <CiEdit className="text-[1.2rem]" />
                </Button>
              </Skeleton>
              <Skeleton
                active
                paragraph={{ rows: 1, width: " 100%" }}
                title={false}
                loading={item.loading}
              >
                {userDetails.isSuperUser ? (
                  <Button
                    type="primary"
                    danger
                    disabled={
                      item.isSuperUser &&
                      item.isSuperUser === userDetails.isSuperUser
                    }
                    onClick={() => deleteModal(item)}
                    size="middle"
                    className={`list-btn-user`}
                  >
                    <CiTrash className="text-[1.2rem]" />
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    danger
                    disabled={
                      (item.IsLeader &&
                        item.isLeader === userDetails.isLeader) ||
                      userDetails.isSuperUser !== item.isSuperUser
                    }
                    onClick={() => deleteModal(item)}
                    size="middle"
                    className={`list-btn-user`}
                  >
                    <CiTrash className="text-[1.2rem]" />
                  </Button>
                )}
              </Skeleton>
            </div>
          </Card>
        </List.Item>
      )}
    />
  );
};
export default UsersList;
