import { EventEmitter } from 'events';

class Darkness {

    static icc = new EventEmitter()

    static publish (channel, data) { this.icc.emit(channel, data) }
    static subscribe(channel, callback) { this.icc.on(channel, callback) }
    static unsubscribe(channel) { this.icc.removeAllListeners(channel) }
}

export default Darkness