import React, { useEffect, useState } from "react";
import { Image, Col } from "antd";
import avatarDefault from "../../assets/images/avatar-default.png";
import { markAsViewed } from "../../services/notification";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const NotificationItem = ({ item, user, reload, lang, noAction }) => {
  const { t } = useTranslation();
  const creationDate = item?.creationDate;
  const fileUpload = item?.fileUpload;
  const isViewed = item?.isViewed;
  const createdByUser = item?.createdByUser;
  const avatar = createdByUser?.avataUrl || avatarDefault;
  const fullName = createdByUser?.fullName;
  const fileType = fileUpload?.fileType;
  const langFileNames = fileUpload?.langFileNames || [];
  const langFolderNames = fileUpload?.langFolderNames || [];
  const fileName = fileUpload?.fileName;
  const folderName = fileUpload?.folderName;
  const langFileName = langFileNames.find(langFile => langFile?.cultureCode === lang);
  const langFolderName = langFolderNames.find(langFolder => langFolder?.cultureCode === lang);

  const handleMarkAsView = async () => {
    try {
      const body = {
        userId: user?.id,
        notificationId: item?.notifyId,
      };

      await markAsViewed(body);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleClickNotification = async () => {
    await handleMarkAsView();
    reload();
    window.location.href = `/documents/${fileUpload?.folderId}?fileType=${fileUpload?.fileType}&fileId=${fileUpload?.id}`;
  };

  if (noAction) {
    return (
      <Col span={24} className="w-full overflow-hidden rounded-md relative">
        <div className="w-full flex items-start justify-left gap-2">
          <div className="bg-[#BFBFBF] rounded-full overflow-hidden h-[50px] w-[50px] flex flex-shrink-0 items-center justify-center">
            <Image
              preview={false}
              className="bg-[#BFBFBF] rounded-full object-cover"
              width={50}
              height={50}
              src={avatar}
              alt="avatar"
            />
          </div>
          <div className="w-full">
            <p className="text-sm m-0 font-semibold text-[#000000]">
              {fullName} {t("uploaded")} {t(`${fileType}`)}
            </p>
            <p className="text-sm m-0">
              <span className="font-semibold text-[#000000]">{t("folder")}:</span>{" "}
              {langFolderName?.folderName || folderName}
            </p>
            <p className="text-sm m-0">
              <span className="font-semibold text-[#000000]">{t("file_name")}:</span>{" "}
              {langFileName?.fileName || fileName}
            </p>
            <span className="text-xs block mt-1">
              {moment(creationDate).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
        </div>
      </Col>
    );
  }

  return (
    <Col
      span={24}
      className="w-full overflow-hidden my-1 px-2 py-2 rounded-md relative hover:bg-[rgba(0,0,0,0.06)] cursor-pointer"
      onClick={() => handleClickNotification()}
    >
      <div className="w-full flex items-start justify-left gap-2">
        <div className="bg-[#BFBFBF] rounded-full overflow-hidden h-[35px] w-[35px] max-w-[100%] flex flex-shrink-0 items-center justify-center mt-1">
          <Image
            preview={false}
            className="bg-[#BFBFBF] rounded-full object-cover"
            width={35}
            height={35}
            src={avatar}
            alt="avatar"
          />
        </div>
        <div className="w-full">
          <p className="text-sm m-0 font-semibold text-[#000000]">
            {fullName} {t("uploaded")} {t(`${fileType}`)}
          </p>
          <p className="text-sm m-0">
            <span className="font-semibold text-[#000000]">{t("folder")}:</span>{" "}
            {langFolderName?.folderName || folderName}
          </p>
          <p className="text-sm m-0">
            <span className="font-semibold text-[#000000]">{t("file_name")}:</span>{" "}
            {langFileName?.fileName || fileName}
          </p>
          <span className="text-xs block mt-1">
            {moment(creationDate).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
        {!isViewed && (
          <div className="w-2 h-2 bg-primary rounded-full absolute right-1 top-1"></div>
        )}
      </div>
    </Col>
  );
};

export default NotificationItem;
