import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

/*
    type Props = {
        
    } & ModalProps
*/
export default function Destroyer(props) {

    const { t } = useTranslation()

    return <Modal {...props} className="!w-[400px]" centered footer={null}>
        <p>{props.warning}</p>
        <div className="mt-4 flex w-full space-x-2 items-center justify-end">
            <Button onClick={props.onCancel} type="primary">{t("cancel")}</Button>
            <Button type="primary" onClick={props.action} danger>{t("trang_thung_rac.xoa_cac_muc")}</Button>
        </div>
    </Modal>
}