import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    uploadQueue: []
}

const uploaderSlice = createSlice({
    name: 'uploader',
    initialState,
    reducers: {
        addToQueue: (state, action) => {
            state.uploadQueue = [...state.uploadQueue, ...action.payload]
        },
        removeFromQueue: (state, action) => {

        }
    },
})

export const { addToQueue } = uploaderSlice.actions;
export default uploaderSlice.reducer;