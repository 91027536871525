import { createSlice } from "@reduxjs/toolkit";
import { SlicesName } from "../../../utils/constants";
import { localStorageKeys } from "../../../utils/constants/local_storage";
import { setUserTokenAction, handleOpenModalUserFormAction } from "./actions";
import { login, getAllUsers, getUserInfor } from "./thunks";
import { userInitialState } from "./initialState";

export const userSlice = createSlice({
  name: SlicesName.Users,
  initialState: userInitialState,
  reducers: {
    setUserToken: setUserTokenAction,
    handleOpenModalUserForm: handleOpenModalUserFormAction,
    setRefreshedToken: (state, action) => {
      state.userLoginInfo.token = action.payload.data.token;
    },
    logout: (state) => {
      state.userLoginInfo.token = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.userLoginInfo.loading = false;
      state.userLoginInfo.error = null;
      if (!!action.payload?.data) {
        state.userLoginInfo.token = action.payload?.data.token;
        state.userLoginInfo.user = action.payload?.data?.data;
        state.userLoginInfo.success = true;
        localStorage.setItem(
          localStorageKeys.TOKEN,
          action.payload?.data.token
        );
        localStorage.setItem(
          localStorageKeys.MY_INFO,
          JSON.stringify(action.payload?.data.data)
        );
      }
    });
    builder.addCase(login.pending, (state, action) => {
      state.userLoginInfo.loading = true;
      state.userLoginInfo.error = null;
      state.userLoginInfo.token = null;
      state.userLoginInfo.success = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.userLoginInfo.loading = false;
      state.userLoginInfo.error = action.payload;
      state.userLoginInfo.success = false;
      state.userLoginInfo.statusCode = action.payload.status;
    });

    // builder.addCase(logout.fulfilled, (state) => {
    //   state.userLoginInfo.token = null;
    //   localStorage.clear();
    // });

    builder.addCase(getUserInfor.fulfilled, (state, action) => {
      state.userLoginInfo.loading = false;
      state.userLoginInfo.error = null;
      if (!!action.payload?.data) {
        state.userLoginInfo.user = action.payload.data;
        localStorage.setItem(
          localStorageKeys.MY_INFO,
          JSON.stringify(action.payload?.data)
        );
      }
    });

    // GET ALL USERS
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.error = null;
      if (action.payload?.status === 200) {
        state.allUsers.data = action.payload?.data.filter((a) => !a.userName);
        state.allUsers.success = true;
      }
    });
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.allUsers.loading = true;
      state.allUsers.error = null;
      state.allUsers.data = [];
      state.allUsers.success = false;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.error = action.payload;
      state.allUsers.success = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserToken,
  handleOpenModalUserForm,
  setRefreshedToken,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
