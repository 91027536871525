import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { createUser, updateUser } from "../../app/features/users/thunks";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import { useTranslation } from "react-i18next";

function UserFormAction({ stateModal, closeModal, actionUsers, department, sites }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isOpenModal, isCreate, titleModal, buttonOkModal, editData } =
    stateModal;
  const [loading, setLoading] = useState(false);
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );
  const userId = userDetails ? userDetails.id : null;
  const ownerUser = editData && editData.Id == userId;

  // useEffect(() => {
  //   if (userDetails.isLeader && userDetails.departmentId !== null)
  //     getDepartmentName(userDetails.departmentId);
  // }, []);

  useEffect(() => {
    setLoading(false);
    if (editData) {
      form.setFieldsValue({
        ...editData,
        departmentId: editData?.departmentId,
        isActive: true,
      });
    }
    return () => {
      form.resetFields();
    };
  }, [isOpenModal, editData, form]);

  // const getDepartmentName = async (id) => {
  //   try {
  //     const res = await getDepartmentDetail(id);
  //     setDepartmentName(res.departmentName);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getDepartment = async () => {
  //   try {
  //     const res = await getDepartments();
  //     if (res) {
  //       setDepartmentData(res.data);
  //     }
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   getDepartment();
  // }, []);

  const filterOption = useCallback((input, option) =>
    option?.label.toLowerCase().includes(input.toLowerCase()),
  []);

const siteOptions = useMemo(() =>
  sites?.map(({ id, siteName }) => ({
      value: id,
      label: siteName,
    })),
  [sites]
);

  const onFinish = async (fieldValues) => {
    setLoading(true);
    if (isCreate) {
      const res = await dispatch(createUser(fieldValues));
      if (res.payload.success) {
        message.success(t("create_employee_success"));
        actionUsers(res.payload?.data, "create");
        closeModal();
      } else {
        for (let error in res.payload.errors) {
          res.payload.errors[error].forEach((err) => {
            message.error(err);
          });
        }
      }
    } else {
      let dataUpdate = {
        ...fieldValues,
        id: editData.id,
        isLeader:
          fieldValues?.isLeader !== undefined
            ? fieldValues.isLeader
            : editData.isLeader,
        isSuperUser:
          fieldValues?.isSuperUser !== undefined
            ? fieldValues.isSuperUser
            : editData.isSuperUser,
        isActive:
          fieldValues?.isActive !== undefined
            ? fieldValues.isActive
            : editData.isActive,
        isBlock:
          fieldValues?.isBlock !== undefined
            ? fieldValues.isBlock
            : editData.isBlock,
        avataUrl: editData.avataUrl,
        dateOfBirth: editData.dateOfBirth,
      };

      const res = await dispatch(updateUser(dataUpdate));
      if (res.payload.success) {
        message.success(t("edit_employee_success"));
        actionUsers(res.payload?.data, "update");
        closeModal();
      } else {
        for (let error in res.payload.errors) {
          res.payload.errors[error].forEach((err) => {
            message.error(err);
          });
        }
      }
    }
    setLoading(false);
  };

  const changeSuperUser = (value) => {
    if (value) {
      form.resetFields(["siteId"])
    }
  }

  return (
    <>
      <Drawer
        title={titleModal}
        open={isOpenModal}
        onClose={closeModal}
        width={720}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          initialValues={{
            isActive: true,
            username: editData?.userName,
            fullName: editData?.fullName,
            userEmail: editData?.userEmail,
            phoneNumber: editData?.PhoneNumber,
            departmentId: editData?.departmentId,
            isBlock: editData?.isBlock,
            isLeader: editData?.isLeader,
            isSuperUser: editData?.isSuperUser,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="username"
                label={t("user_name")}
                rules={[
                  {
                    required: true,
                    message: t("required_user_name"),
                  },
                  {
                    pattern: /^[a-z0-9]+$/,
                    message: t("validate_user_name"),
                  },
                ]}
              >
                <Input
                  placeholder={`${t("input_user_name")}...`}
                  disabled={!isCreate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {isCreate ? (
                <Form.Item
                  name="password"
                  label={t("password")}
                  rules={[
                    {
                      required: true,
                      message: t("required_password"),
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder={`${t("input_password")}...`}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item name="password" label={t("password")}>
                  <Input.Password
                    type="password"
                    placeholder={`${t("input_password")}...`}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fullName"
                label={t("employee_name")}
                rules={[
                  {
                    required: true,
                    message: t("required_full_name"),
                  },
                ]}
              >
                <Input placeholder={`${t("input_full_name")}...`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="userEmail"
                label={t("email_address")}
                rules={[
                  {
                    type: "email",
                    message: t("validate_email"),
                  },
                ]}
              >
                <Input placeholder={`${t("input_email")}...`} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label={t("number_phone")}
                rules={[
                  {
                    pattern: /^0\d{9}$/,
                    message: t("validate_number_phone"),
                  },
                ]}
              >
                <Input
                  placeholder={`${t("input_number_phone")}...`}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="siteId"
                label={t("site")}
                initialValue={userDetails.siteId}
                rules={[
                  ({ getFieldValue }) => ({
                    required: !getFieldValue("isSuperUser"),
                    message: t("required_site"),
                  }),
                ]}
              >
                <Select
                  placeholder={`${t("select_site")}...`}
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  options={siteOptions}
                  disabled={!userDetails.isSuperUser}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="departmentId"
                label={t("department")}
                initialValue={userDetails.departmentId}
                rules={[
                  ({ getFieldValue }) => ({
                    required: !getFieldValue("isSuperUser"),
                    message: t("required_department"),
                  }),
                ]}
              >
                <Select placeholder={`${t("select_department")}...`} allowClear disabled={!userDetails.isSuperUser}>
                  {department?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.departmentName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            {!ownerUser ? (
              <Col span={12}>
                <Form.Item name="isLeader" label={t("appointed_leader")}>
                  <Switch
                    className="switch-role"
                    disabled={
                      !userDetails.isSuperUser &&
                      userDetails.isLeader &&
                      userDetails.id === editData?.Id
                    }
                  />
                </Form.Item>
              </Col>
            ) : (
              userDetails.isSuperUser && (
                <Col span={12}>
                  <Form.Item name="isLeader" label={t("appointed_leader")}>
                    <Switch
                      className="switch-role"
                      disabled={
                        !userDetails.isSuperUser &&
                        userDetails.isLeader &&
                        userDetails.id === editData?.Id
                      }
                    />
                  </Form.Item>
                </Col>
              )
            )}{" "}
            {userDetails.isSuperUser && (
              <Col span={12}>
                <Form.Item name="isSuperUser" label={t("appointed_admin")}>
                  <Switch className="switch-role" onChange={changeSuperUser} />
                </Form.Item>
              </Col>
            )}
            {!isCreate && !ownerUser && (
              <Col span={12}>
                <Form.Item name="isBlock" label={t("account_status")}>
                  <Select>
                    <Select.Option value={false}>{t("active")}</Select.Option>
                    <Select.Option value={true}>{t("block")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          {/* <Row gutter={16} className="hidden">
            <Col span={24}>
              <Form.Item
                name="avataUrl"
                label="Ảnh đại diện"
                rules={[
                  {
                    required: false,
                    message: "please enter url description",
                  },
                ]}
              >
                Ảnh
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16} justify={"end"}>
            <Col span={24}>
              <Space>
                <Button danger type="primary" onClick={closeModal} className="">
                  {t("cancel")}
                </Button>
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="border-[#ff9900]  hover:!border-[#ff9900] !bg-[#ff9900] !text-[#fff] hover:opacity-80 transition"
                >
                  {buttonOkModal}
                </Button>
              </Space>
            </Col>
          </Row>

          <Col span={12} hidden>
            <Form.Item name="isActive" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Form>
      </Drawer>
    </>
  );
}

export default UserFormAction;
