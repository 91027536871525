import { Button, Form, Modal, Switch } from "antd"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoLink } from "react-icons/io5";
import { changeFileName, shareFile } from "../../../services/document";
import copier from "../../../utils/copier";

export default function DocumentSharing(props) {

    const { t, i18n } = useTranslation();
    const [isPublic, setIsPublic] = useState(props.data.isPublic)

    const onChange = async (checked) => {
        await shareFile({ id: props.data.id, isPublic: checked })
        setIsPublic(checked)
    }

    const getSharingLink = async () => {
        await copier(process.env.REACT_APP_SHARE_FILE + "/" + props.data.code)
    }

    return <Modal centered footer={null} title={`${t("docPage.chia_se")} : ${props.data.fileName}`} {...props} className="!w-[690px]">
        <Form layout="vertical" className="">
            <Form.Item style={{ borderTop: "1px solid" }} name="department" label={t("docPage.chia_se_toi_moi_nguoi")} className="!border-gray-200 pt-4">
                <div className="space-x-4 flex items-center">
                    <Switch onChange={onChange} checked={isPublic} />
                    <span className="px-2 py-1 block">{t("docPage.chia_se_public_note")}</span>
                </div>
            </Form.Item>
            <Button onClick={getSharingLink} className="shadow-m flex items-center" icon={<IoLink />}>{t("docPage.sao_chep_link")}</Button>
        </Form>
    </Modal>
}