import {
  AiOutlineArrowUp,
  AiOutlineReload,
  AiOutlineSearch,
} from "react-icons/ai";
import {
  Breadcrumb,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Spin,
} from "antd";
import DocumentImage from "../../components/document/document.image";
import DocumentVideo from "../../components/document/document.video";
import DocumentFolder from "../../components/document/document.folder";
import { useTranslation } from "react-i18next";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  createFolder,
  getFiles,
  getFolderDetail,
  getFolders,
  getFolderByCode,
} from "../../services/document";
import Darkness from "../../lib/darkness";
import DocumentUploader from "../../components/document/document.uploader";
import DocumentPreview from "../../components/document/document.preview";
import { GoUpload } from "react-icons/go";
import { BsFolderPlus } from "react-icons/bs";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import { checkFullPermissionOnFile } from "../../utils/utils";
import dayjs from "dayjs";
import debounce from "debounce";
import { IMAGE_MIME_SUPPORT, VIDEO_MIME_SUPPORT } from "../../constants/media";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { nanoid } from "nanoid";
import InfiniteScroll from "react-infinite-scroll-component";
import FolderCreator from "../../components/document/document.folderCreator";

const { RangePicker } = DatePicker;

export default function DocumentParent() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [parentDetail, setParentDetail] = useState(undefined);
  const nav = useNavigate();
  const [uploadQueue, setUploadQueue] = useState([]);
  const [isOpenUploader, setIsOpenUploader] = useState(false);
  const [isOpenFolderCreator, setIsOpenFolderCreator] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showUploader, toggleUploader] = useState(true);
  const filesBox = useRef(null);
  const uploader = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(2);
  const [total, setTotal] = useState(0);
  let isOpenViewFile = searchParams.get("fileType");
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );
  const isFullPermission = checkFullPermissionOnFile(userDetails);

  const load = useCallback(
    async (
      parent = undefined,
      textSearch = undefined,
      fromDate = undefined,
      toDate = undefined
    ) => {
      setIsLoading(true);
      const [gotFolders, gotFiles, gotDetail] = await Promise.all([
        getFolders({
          parentFoldeId: parent ?? id,
          pageSize: 100,
          ...(!!textSearch && { textSearch }),
          ...(!!fromDate && !!toDate && { fromDate, toDate }),
        }),
        getFiles({
          folderId: parent ?? id,
          pageSize: 20,
          ...(!!textSearch && { textSearch }),
          ...(!!fromDate && !!toDate && { fromDate, toDate }),
        }),
        getFolderDetail(id),
      ]);

      if (!gotDetail.success) {
        setIsLoading(false);
        nav("/not-found");
      }

      setParentDetail(gotDetail.data);
      setFolders(gotFolders.data.data);
      setFiles(gotFiles.data.data);
      setTotal(gotFiles.data.total);
      setHasMore(gotFiles.data.isNextPage);
      setIsLoading(false);

      if (isOpenViewFile) {
        setTimeout(() => {
          openViewFile();
        }, 100);
      }
    },
    [id]
  );

  const fetchMore = async () => {
    try {
      const getFile = await getFiles({
        folderId: id,
        pageSize: 20,
        pageIndex: index,
        ...(!!textSearch && { textSearch }),
        ...(!!startDate &&
          !!endDate && { fromDate: startDate, toDate: endDate }),
      });

      // Nếu không còn dữ liệu, thoát sớm
      if (!getFile.data || !getFile.data.data.length) {
        message.info("Không còn dữ liệu để tải.");
        return;
      }

      setFiles((prevItems) => [...prevItems, ...getFile.data.data]);
      setHasMore(getFile.data.isNextPage);
      setIndex(index + 1);
    } catch (error) {
      console.error("Lỗi khi tải thêm dữ liệu:", error);
      message.error("Có lỗi xảy ra khi tải thêm dữ liệu.");
    }
  };

  const openViewFile = () => {
    const fileId = searchParams.get("fileId");
    const fileType = searchParams.get("fileType");

    const elementFile = document.getElementById(`${fileType}-${fileId}`);

    if (elementFile) {
      elementFile.click();
    } else {
      message.error(t("file_not_found_deleted"));
    }

    const url = new URL(window.location.href);
    url.searchParams.delete("fileType");
    url.searchParams.delete("fileId");
    window.history.replaceState(null, "", url.toString());
    isOpenViewFile = false;
  };

  const onChangeDate = async (date) => {
    if (date) {
      const startDateString = dayjs(date[0]).add(1, "day").toISOString();
      const endDateString = dayjs(date[1]).add(1, "day").toISOString();

      setStartDate(startDateString);
      setEndDate(endDateString);
      await load(id, textSearch, startDateString, endDateString);
    } else {
      setStartDate("");
      setEndDate("");
      await load(id, textSearch, "", "");
    }
  };

  const loadFiles = async (folderId, textSearch = undefined) => {
    const gotFiles = await getFiles({
      folderId,
      pageSize: 75,
      ...(!!textSearch && { textSearch }),
    });
    setFiles(gotFiles.data.data);
  };

  const loadFolders = async (parentFoldeId, textSearch = undefined) => {
    const gotFolders = await getFolders({
      parentFoldeId,
      pageSize: 75,
      ...(!!textSearch && { textSearch }),
    });
    setFolders(gotFolders.data.data);
  };

  useEffect(() => {
    load(id);

    Darkness.subscribe("reload-files", async (args) => {
      console.log(`reload-files`, args);
      await loadFiles(args);
    });

    Darkness.subscribe("reload-folders", async (args) => {
      console.log(`reload-files`, args);
      await loadFolders(args);
    });
  }, [id]);

  const onFileSelected = (event) => {
    let listOfFile = [];
    let originalFiles = event.target.files;
    if (originalFiles) {
      for (let f of originalFiles) {
        listOfFile.push({
          name: f.name,
          fileName_VN: "",
          fileName_CN: "",
          fileName_TH: "",
          mime: f.type,
          size: f.size,
          file: f,
          status: 1,
        });
      }
      if (listOfFile.length === 0) {
        return;
      }
      setIsOpenUploader(true);
      setUploadQueue(listOfFile);
    }
  };

  const upload = () => {
    setIsOpenUploader(false);

    if (uploadFiles.length > 0) {
      // neu da co
      setUploadFiles((prev) => [
        ...prev,
        ...uploadQueue.map((i, index) => ({
          ...i,
          rawId: nanoid(),
          ...(index === 0 && { newFlow: true }),
        })),
      ]);
      filesBox.current.files = null;
      return;
    }

    setUploadFiles((prev) => [
      ...uploadQueue.map((i) => ({ ...i, rawId: nanoid() })),
    ]);
    filesBox.current.files = null;
  };

  const handleSelectFolder = useCallback(async () => {
    try {
      const directoryHandle = await window.showDirectoryPicker();
      const directoryName = await directoryHandle.name;
      const entries = directoryHandle.entries();

      const { data } = await createFolder({
        languageFolders: [
          { cultureDataId: 1, folderName: directoryName },
          { cultureDataId: 2, folderName: directoryName },
          { cultureDataId: 3, folderName: directoryName },
        ],
        parentFoldeId: id,
      });

      let filesContainer = [];

      for await (const entry of entries) {
        const [, obj] = entry;
        if (obj.kind === "file") {
          const file = await obj.getFile();
          if (
            VIDEO_MIME_SUPPORT.includes(file.type) ||
            IMAGE_MIME_SUPPORT.includes(file.type)
          ) {
            filesContainer.push({
              rawId: nanoid(),
              name: file.name,
              file,
              fileName_VN: file.name,
              fileName_CN: file.name,
              fileName_TH: file.name,
              size: file.size,
              mime: file.type,
              parent: data.id,
              loadFolders: true,
            });
          }
        }
      }

      if (uploadFiles.length > 0) {
        setUploadFiles((prev) => [
          ...prev,
          ...filesContainer.map((F, index) => ({
            ...F,
            ...(index === 0 && { newFlow: true }),
          })),
        ]);
      } else {
        setUploadFiles(filesContainer);
      }
    } catch (err) {
      console.log(err);
    }
  }, [id, uploadFiles]);

  const cancelUpload = () => {
    setIsOpenUploader(false);
    setUploadQueue([]);

    if (filesBox.current?.files) {
      filesBox.current.files = null;
    }
  };

  const onChangeUploadQueue = (index, args) => {
    const [key, value] = args;
    setUploadQueue((prev) => {
      let _prev = [...prev];
      _prev[index] = { ..._prev[index], [key]: value };
      return _prev;
    });
  };

  const item = (data) => {
    switch (data.fileType) {
      case "image":
        return (
          <DocumentImage
            data={data}
            key={data.key}
            isFullPermission={isFullPermission}
          />
        );
      case "video":
        return (
          <DocumentVideo
            data={data}
            key={data.key}
            isFullPermission={isFullPermission}
          />
        );

      default:
        return (
          <div key={data.key} className="các">
            {data.id}
          </div>
        );
    }
  };

  const newFolder = useCallback(
    async (values) => {
      const { data } = await createFolder({
        languageFolders: [
          { cultureDataId: 1, folderName: values.vnFolderName },
          { cultureDataId: 2, folderName: values.zhFolderName },
          { cultureDataId: 3, folderName: values.thFolderName },
        ],
        parentFoldeId: id,
      });
      setIsOpenFolderCreator(false);
      Darkness.publish("reload-folders", id);
    },
    [id]
  );

  const goGrandParent = () => {
    if (parentDetail?.parentFoldeId) {
      nav("/documents/" + parentDetail?.parentFoldeId);
    } else {
      nav("/documents");
    }
  };

  const onSearch = async (values) => {
    setTextSearch(values);
    await load(id, values, startDate, endDate);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 500),
    [startDate, endDate, id]
  );

  const reloadForm = () => {
    setEndDate("");
    setStartDate("");
    setTextSearch("");
    form.resetFields();
    load(id);
  };

  const uploadLabels = [
    {
      key: "1",
      label: (
        <label
          htmlFor="file_selector"
          className="cursor-pointer flex items-center space-x-2 px-2 rounded-md"
        >
          {t("docPage.uploadFile")}
        </label>
      ),
    },
    {
      key: "2",
      label: (
        <span
          className="cursor-pointer flex items-center space-x-2 px-2 rounded-md"
          onClick={handleSelectFolder}
        >
          {t("docPage.uploadFolder")}
        </span>
      ),
    },
  ];

  const hiddenUploader = () => {
    if (showUploader) {
      toggleUploader(false);
      uploader.current?.classList.add("hidden");
    } else {
      toggleUploader(true);
      uploader.current?.classList.remove("hidden");
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="fixed top-[40%] left-[55%]">
          <Spin />
        </div>
      )}
      <div
        style={{ border: "solid 1px" }}
        className="bg-gray-100 !border-gray-300 flex py-2 px-3 rounded-md justify-between w-full items-center"
      >
        <div className="flex text-gray-600 items-center space-x-4">
          <AiOutlineArrowUp
            onClick={goGrandParent}
            className="cursor-pointer text-lg"
          />
          <AiOutlineReload
            onClick={reloadForm}
            className="cursor-pointer text-lg"
          />
          <Breadcrumb
            items={[
              {
                title: "...",
              },
              {
                title: `${parentDetail && parentDetail.folderName}`,
              },
            ]}
          />
        </div>

        <Form className="w-[60%] flex" layout="vertical" form={form}>
          <Form.Item noStyle name="textSearch">
            <Input
              allowClear
              onChange={(e) => debouncedSearch(e.target.value)}
              suffix={<AiOutlineSearch className="text-lg" />}
              placeholder={t("docPage.tim_kiem_text")}
            />
          </Form.Item>
          <Form.Item noStyle name="dateTime">
            <RangePicker
              placeholder={[
                t("search_history.start_day"),
                t("search_history.end_day"),
              ]}
              format={"DD/MM/YYYY"}
              allowClear
              className="min-w-80 ml-2"
              onChange={(date) => onChangeDate(date)}
            />
          </Form.Item>
        </Form>

        <div className="flex items-center space-x-2">
          {isFullPermission && (
            <>
              <div
                onClick={() => setIsOpenFolderCreator(true)}
                className="cursor-pointer flex items-center space-x-2 rounded-md bg-gray-100 px-2 py-1"
              >
                <BsFolderPlus className="text-lg" />
                <span>{t("docPage.createFolder")}</span>
              </div>
              <Dropdown menu={{ items: uploadLabels }} trigger={["click"]}>
                <div className="flex space-x-1 items-center cursor-pointer">
                  <GoUpload className="text-lg" />
                  <span>{t("upload")}</span>
                </div>
              </Dropdown>

              <input
                type="file"
                ref={filesBox}
                onChange={onFileSelected}
                id="file_selector"
                className="hidden"
                accept="video/*,image/*"
                multiple
              />
            </>
          )}
        </div>
      </div>

      {uploadFiles.length > 0 && (
        <div className="fixed z-10 bottom-0 right-6 shadow-lg">
          <div className="bg-amber-500 flex justify-between space-x-2 items-center px-6 py-[1px] rounded-t-xl">
            <p className="font-medium text-white">
              Uploaded {uploadFiles.length} file
            </p>
            {showUploader ? (
              <IoIosArrowUp
                className="cursor-pointer text-white"
                onClick={hiddenUploader}
              />
            ) : (
              <IoIosArrowDown
                className="cursor-pointer text-white"
                onClick={hiddenUploader}
              />
            )}
          </div>
          <div ref={uploader} className="bg-white max-h-[85vh] overflow-y-auto">
            {uploadFiles.map((item, index) => (
              <DocumentUploader
                first={index === 0}
                next={uploadFiles[index + 1]}
                key={`upload_file__${index}`}
                uploadData={item}
                prefix={String(Date.now())}
              />
            ))}
          </div>
        </div>
      )}

      <FolderCreator
        newFolder={newFolder}
        open={isOpenFolderCreator}
        onCancel={() => setIsOpenFolderCreator(false)}
      />

      <Modal
        destroyOnClose
        title={`${t("docPage.uploadFile")} (${uploadQueue.length})`}
        open={isOpenUploader}
        footer={null}
        className="!w-[1000px]"
        onCancel={cancelUpload}
      >
        <div className="space-y-2">
          {uploadQueue.map((f, i) => (
            <DocumentPreview
              key={i}
              data={f}
              index={i}
              onChangeUploadQueue={onChangeUploadQueue}
            />
          ))}

          <div className="flex space-x-2 justify-end">
            <Button onClick={cancelUpload}>{t("cancel")}</Button>
            <Button onClick={upload} type="primary">
              {t("upload")}
            </Button>
          </div>
        </div>
      </Modal>

      <section>
        <p className="text-base font-medium flex items-center space-x-1">
          <span>{t("folder")}</span>
        </p>
        <div className="grid grid-cols-5 gap-4">
          {folders.map((doc) => (
            <DocumentFolder
              key={doc.id}
              data={doc}
              isFullPermission={isFullPermission}
            />
          ))}
        </div>
      </section>

      <section>
        <p className="text-base font-medium flex items-center space-x-1">
          <span>{t("file")}</span>
        </p>
        <InfiniteScroll
          dataLength={files.length}
          next={fetchMore}
          hasMore={hasMore}
          loader={<h4>{t("loading")}...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("all_document_mes")}</b>
            </p>
          }
        >
          <div className="grid grid-cols-5 gap-4">
            {files.map((doc) => (
              <Fragment key={doc.id}>{item(doc)}</Fragment>
            ))}
          </div>
        </InfiniteScroll>
      </section>
    </div>
  );
}
