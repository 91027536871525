import { Button, Form, Input, Modal } from "antd";
import { useCallback, useRef, useState } from "react";
import { Outlet, useParams } from "react-router";
import DocumentUploader from "../../components/document/document.uploader";
import DocumentPreview from "../../components/document/document.preview";
import { useTranslation } from "react-i18next";
import { createFolder } from "../../services/document";
import DocumentPickerActions from "../../components/document/picker/picker.actions";
import Darkness from "../../lib/darkness";

export default function DocumentLayout() {
  document.title = "Tài liệu";
  const [uploadQueue, setUploadQueue] = useState([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const [isOpenUploader, setIsOpenUploader] = useState(false);
  const [isOpenFolderCreator, setIsOpenFolderCreator] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const filesBox = useRef(null);

  const newFolder = useCallback(
    async (values) => {
      const { data } = await createFolder({
        languageFolders: [
          { languageCode: "vn", folderName: values.vnFolderName },
          { languageCode: "cn", folderName: values.zhFolderName },
          { languageCode: "th", folderName: values.thFolderName },
        ],
        parentFoldeId: id ?? null,
      });
      setIsOpenFolderCreator(false);
      Darkness.publish("reload", undefined);
    },
    [id]
  );

  const upload = () => {
    setIsOpenUploader(false);
    console.log(61, uploadQueue);
    setUploadFiles(uploadQueue);
    filesBox.current.files = null;
  };

  const cancelUpload = () => {
    setIsOpenUploader(false);
    setUploadQueue([]);
  };

  const onChangeUploadQueue = (index, args) => {
    const [key, value] = args;
    setUploadQueue((prev) => {
      let _prev = [...prev];
      _prev[index] = { ..._prev[index], [key]: value };
      return _prev;
    });
  };

  return (
    <div className="relative">
      {uploadFiles.length > 0 && (
        <div className="fixed bottom-0 right-6 bg-white shadow-lg">
          <div className="bg-amber-500 px-6 py-[1px] rounded-t-xl">
            <p className="font-medium text-white">
              Uploaded {uploadFiles.length} file
            </p>
          </div>
          {uploadFiles.map((item, index) => (
            <DocumentUploader key={`acqcwc_${index}`} uploadData={item} />
          ))}
        </div>
      )}

      <Modal
        title={t("docPage.uploadFile")}
        open={isOpenUploader}
        footer={null}
        className="!w-[1000px]"
        onCancel={cancelUpload}
      >
        <div className="space-y-2">
          {uploadQueue.map((f, i) => (
            <DocumentPreview
              key={i}
              data={f}
              index={i}
              onChangeUploadQueue={onChangeUploadQueue}
            />
          ))}

          <div className="flex space-x-2 justify-end">
            <Button onClick={cancelUpload}>{t("cancel")}</Button>
            <Button onClick={upload} type="primary">
              {t("upload")}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        destroyOnClose
        title={t("docPage.createFolder")}
        open={isOpenFolderCreator}
        footer={null}
        onCancel={() => setIsOpenFolderCreator(false)}
      >
        <Form
          onFinish={newFolder}
          layout="vertical"
          className="w-full darkness-form"
        >
          <Form.Item
            name="vnFolderName"
            label={t("docPage.viFolderName")}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zhFolderName"
            label={t("docPage.zhFolderName")}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="thFolderName"
            label={t("docPage.thFolderName")}
            required
          >
            <Input />
          </Form.Item>
          <div className="flex space-x-2 items-center">
            <Button htmlType="submit">{t("submit")}</Button>
          </div>
        </Form>
      </Modal>

      <div className="relative header w-full flex justify-between space-x-2 border-b-2">
        <DocumentPickerActions />
      </div>
      <Outlet />
    </div>
  );
}
