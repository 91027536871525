import React from "react";
import {
  UserOutlined,
  FolderOpenOutlined,
  FileSearchOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import { routes } from "../../utils/constants/routes";
import { Menu, MenuItem } from "react-pro-sidebar";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;
export const parentRoute = {
  [routes.home]: routes.home,
};

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const route = `/${location.pathname
    .split("/")
    .filter((a) => !!a)
    .join("/")}`;

  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );
  const isSupperUser = userDetails?.isSuperUser;
  const isBaDepartment = userDetails?.department?.isBa;
  const isLeader = userDetails?.isLeader;

  const menuItems = [
    {
      key: "documents",
      name: t("sidebar.document"),
      route: "/documents",
      icon: <FolderOpenOutlined />,
      isAllow: true,
    },
    {
      key: "sites",
      name: t("sidebar.site_manager"),
      route: "/sites",
      icon: <UnorderedListOutlined />,
      isAllow: isSupperUser,
    },
    {
      key: "departments",
      name: t("sidebar.department_manager"),
      route: "/departments",
      icon: <ApartmentOutlined />,
      isAllow: isSupperUser,
    },
    {
      key: "users",
      name: t("sidebar.user_manager"),
      route: "/employees",
      icon: <UserOutlined />,
      isAllow: isSupperUser || isLeader,
    },
    {
      key: "logs",
      name: t("sidebar.logs_manager"),
      route: "/logs",
      icon: <FileSearchOutlined />,
      isAllow: isSupperUser,
    },
    {
      key: "recycle-bin",
      name: t("sidebar.trash"),
      route: "/recycle-bin",
      icon: <DeleteOutlined />,
      isAllow: isSupperUser || isBaDepartment,
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={230}
      style={{
        position: "fixed",
        left: 0,
        height: "100vh",
      }}
      theme="light"
    >
      <div className="logo-vertical">
        <a href={"/"}>
          <img src={logo} alt="" />
        </a>
      </div>
      <Menu
        style={{
          borderInline: "none",
          backgroundColor: "transparent",
          padding: "0 5px",
        }}
      >
        {menuItems.map((item) => {
          if (item.isAllow) {
            return (
              <MenuItem
                key={item.key}
                className="custom-menu font-medium text-[15px]"
                active={`/${route.split("/")[1]}` === item.route}
                icon={item.icon}
                onClick={() => navigate(item.route)}
              >
                {item.name}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
