import { S3Client } from '@aws-sdk/client-s3';
import { ListObjectsV2Command, DeleteObjectCommand, GetObjectCommand, AbortMultipartUploadCommand } from '@aws-sdk/client-s3';
import { Upload } from "@aws-sdk/lib-storage";

const s3 = new S3Client({
    forcePathStyle: true,

    endpoint: process.env.REACT_APP_S3_ENDPOINT,
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
    },
    region: process.env.REACT_APP_S3_REGION
})

export function uploader(params, callback = undefined) {
    try {
        const upload = new Upload({
            client: s3,
            queueSize: 4,
            leavePartsOnError: false,
            params: { ...params, ACL: "public-read", Bucket: "video" }
        }).on("httpUploadProgress", (process) => {
            callback && callback({ loaded: process.loaded ?? 0, total: process.total ?? 0 })
        })
        return upload
    } catch (err) {
        console.log("Loi:", err)
    }
}

export async function cancel({ Key, UploadId }) {
    const command = new AbortMultipartUploadCommand({
        Bucket: "video", Key, UploadId
    })
    const res = await s3.send(command)
    return res
}

export async function getObjects() {
    const list = await s3.send(new ListObjectsV2Command({ Bucket: "video" }))
    return list
}

export async function deleteObject(Key) {
    const target = await s3.send(new DeleteObjectCommand({ Bucket: "video", Key }))
    return target
}

export default s3