import loginBG from "../../assets/images/loginBG.svg";
import logo from "../../assets/images/logo.jpg";
import { Button, Form, Input, message, Select } from "antd";
import { login } from "../../app/features/users/thunks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { routes } from "../../utils/constants/routes";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getCultureUiInfors } from "../../services/user";
import th from "../../assets/images/thailand.svg";
import vi from "../../assets/images/vietnam.svg";
import zh from "../../assets/images/china.svg";

const flags = {
  vi: vi,
  th: th,
  zh: zh,
};

const { Option } = Select;

export default function Login() {
  const dispatch = useDispatch();
  const [formLogin] = Form.useForm();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [listLanguage, setListLanguage] = useState([]);

  useEffect(() => {
    const getCultureUi = async () => {
      const res = await getCultureUiInfors();
      if (res.success) {
        setListLanguage(res.data.data);
      }
    };

    getCultureUi();
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setCurrentLanguage(lng);
    window.location.reload();
  };

  const onFinish = (fieldValue) => {
    dispatch(login(fieldValue))
      .then((action) => {
        if (action.payload?.data) {
          message.success(t("login_success"));
          navigate(routes.home);
        } else {
          for (let error in action.payload.errors) {
            action.payload.errors[error].forEach((err) => {
              message.error(err);
            });
          }
        }
      })
      .catch((error) => {
        console.error("Failed to login:", error);
      });
  };

  return (
    <>
      <div className="main">
        <div className="banner">
          <img src={loginBG} alt="" className="banner-login" />
          <img src={logo} alt="" className="logo" />
        </div>
        <div className="right">
          <div className="sign-in-form">
            <h2 className="title">
              {t("login_hello")} <span style={{ color: "#fd9900" }}>OKVIP</span>
              ! 👋
            </h2>
            <Form
              form={formLogin}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 22,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label={t("user_name")}
                name="userName"
                style={{
                  fontWeight: "bold",
                }}
                rules={[
                  {
                    required: true,
                    message: t("error_empty_username"),
                  },
                ]}
              >
                <Input size="large" placeholder={t("placeholder_username")} />
              </Form.Item>

              <Form.Item
                label={t("password")}
                name="password"
                style={{
                  fontWeight: "bold",
                }}
                rules={[
                  {
                    required: true,
                    message: t("error_empty_password"),
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder={t("placeholder_password")}
                />
              </Form.Item>
              <Select
                value={currentLanguage}
                onChange={changeLanguage}
                className="w-[140px]"
              >
                {listLanguage.map((value) => (
                  <Option
                    key={value.cultureCodeI18}
                    value={value.cultureCodeI18}
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={flags[value.cultureCodeI18]}
                        alt="flags"
                        className="w-5 rounded-full"
                      />
                      {t(`languageOptions.${value.cultureCodeI18}`)}
                    </div>
                  </Option>
                ))}
              </Select>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#ffa827",
                    width: "100%",
                    height: "40px",
                    color: "#fff",
                    marginTop: "30px",
                  }}
                  className="custom-button"
                  size="large"
                  htmlType="submit"
                >
                  {t("login")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
