import { Form, Input } from "antd"
import { useTranslation } from "react-i18next";
import DocumentFilePreview from "./document.filePreview"
import getFileType from "../../utils/getFileType";

export default function DocumentPreview({ index, data, onChangeUploadQueue }) {
    const { t, i18n } = useTranslation();
    const url = URL.createObjectURL(data.file)

    const fileType = getFileType(data)

    return <div style={{ border: "1px solid" }} className="rounded-lg !border-gray-300 p-4 flex bg-gray-100 space-x-4">
        <Form layout="vertical" className="w-full darkness-form">
            <Form.Item name="fileName_VN" label={t("docPage.viFileName")}>
                <Input name="fileName_VN" onChange={(e) => onChangeUploadQueue(index, [e.target.name, e.target.value])} />
            </Form.Item>
            <Form.Item name="fileName_CN" label={t("docPage.zhFileName")}>
                <Input name="fileName_CN" onChange={(e) => onChangeUploadQueue(index, [e.target.name, e.target.value])}/>
            </Form.Item>
            <Form.Item name="fileName_TH" label={t("docPage.thFileName")}>
                <Input name="fileName_TH" onChange={(e) => onChangeUploadQueue(index, [e.target.name, e.target.value])} />
            </Form.Item>
            <div className="flex items-center space-x-2 rounded-md px-2 py-1 bg-amber-300">
                <span>{(data.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
        </Form>
        <DocumentFilePreview fileType={fileType} url={url}/>
    </div>
}