import { useEffect, useRef, useState } from "react"
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "react-icons/io"
import { uploader } from "../../lib/s3storage"
import { insertFile } from "../../services/document"
import getFileType from "../../utils/getFileType"
import { useParams } from "react-router"
import Darkness from "../../lib/darkness"

export default function DocumentUploader({ first, uploadData, prefix, next }) {
    const uploadKey = prefix + "/" + uploadData.name
    const [done, setDone] = useState(false)
    const [isStopped, setIsStopped] = useState(false)
    const [percent, setPercent] = useState(0)
    const { id } = useParams()
    const fileType = getFileType(uploadData)

    const upload = useRef(
        uploader({ Key: uploadKey, Body: uploadData.file }, ({ loaded, total }) => {
            if (loaded === total) {
                setDone(true)
                return
            } else { setPercent((loaded / total) * 100) }
        })
    )

    useEffect(() => {

        if (uploadData.newFlow) {
            console.log(`luồng upload mới đã được thêm vào`)
            start()
        }

        if (first) {
            console.log(`khởi động luồng upload hiện tại với mã upload ${uploadData.rawId}`)
            start()
        } else {
            console.log(`[${uploadData.rawId}] đang chờ tín hiệu để khởi động upload`)
            Darkness.subscribe(`upload-${uploadData.rawId}`, async () => {
                console.log(`[${uploadData.rawId}] tiếp nhận tín hiệu khởi động upload`);
                await start()
            })
        }
    }, [])

    const start = async () => {
        try {
            const res = await upload.current.done()
            if (res) {
                await insertFile({
                    fileName: uploadKey,
                    languageFiles: [
                        { fileName: uploadData.fileName_VN, cultureDataId: 1 },
                        { fileName: uploadData.fileName_CN, cultureDataId: 2 },
                        { fileName: uploadData.fileName_TH, cultureDataId: 3 },
                    ],
                    fileSize: uploadData.size,
                    filePath: "https://cdn-video.tailieuokvip.com/video/" + res.Key,
                    fileType: fileType,
                    folderId: uploadData.parent ?? id,
                    isPublic: false
                })
            }

            if (next) {
                console.log(`[${uploadData.rawId}] tải lên thành công, mã upload tiếp theo ${next.rawId} sẽ sớm được tiếp tục`);
                Darkness.publish(`upload-${next.rawId}`)
            } else {
                if (uploadData.loadFolders) {
                    Darkness.publish("reload-folders", id)
                } else Darkness.publish("reload-files", id)
            }

        } catch (err) {
            console.log(err)
            if (next) {
                console.log(`Upload thất bại hoặc file đã bị xóa khỏi hàng đợi, chuyển qua mã upload tiếp theo ${next.rawId}`);
                Darkness.publish(`upload-${next.rawId}`)
            } else {
                if (uploadData.loadFolders) {
                    Darkness.publish("reload-folders", id)
                } else Darkness.publish("reload-files", id)
            }
        }
    }

    const stop = async () => {
        setIsStopped(true)
        if (upload?.current) {
            try {
                await upload.current.abort()
            } catch (err) {
                console.log(err)
            }
        }
    }

    return <div className="px-4 flex py-1 hover:bg-gray-100 justify-between items-center space-x-2">
        <div className="flex space-x-2 items-center">
            {
                !done && <IoMdCloseCircle onClick={stop} className={`cursor-pointer ${isStopped ? "text-gray-400" : "text-red-500"}`} />
            }
            <span className={`${isStopped && "text-gray-400 line-through"} font-medium text-sm`}>{uploadData.name}</span>
        </div>
        {done ? <IoMdCheckmarkCircle className="text-green-600" /> : <span>{percent.toFixed(2)}%</span>}
    </div>
}