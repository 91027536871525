import { Button, message, Modal } from "antd";
import { useState } from "react";
import { FiDownload, FiTrash2, FiShare2 } from "react-icons/fi"
import DocumentSharing from "./sharing";
import DocumentRemover from "./remover";
import { useTranslation } from "react-i18next";
import { deleteTemporaryFile } from "../../services/document";
import Darkness from "../../lib/darkness";

export default function DocumentViewer(props) {
    const isFullPermission = props?.isFullPermission;

    const [openShare, setOpenShare] = useState(false)
    const [openRemover, setOpenRemover] = useState(false)
    const { t, i18n } = useTranslation()

    const renderFile = () => {
        switch (props.data.fileType) {
            case "video":
                return <video className="w-full max-h-[720px]" src={props.data.filePath} controls />
            case "image":
                return <img className="w-full max-h-[720px] object-contain" src={props.data.filePath} />
        }
    }

    const download = () => {
        window.open(props.data.filePath, "_blank")
    }

    const deleteFile = async () => {
        const $deleteFile = await deleteTemporaryFile(props.data.id)

        if ($deleteFile.success) {
            message.success(t("docPage.thong_bao_xoa_thanh_cong"))
            Darkness.publish("reload-files", props.data.folderId)
        } else {
            message.error(t("docPage.thong_bao_xoa_that_bai"))
        }
        setOpenRemover(false)
        props.onCancel()
    }

    return <Modal centered {...props} className="!w-[1200px]" footer={null} destroyOnClose>

        <DocumentSharing open={openShare} onCancel={() => setOpenShare(false)} data={props.data} />
        <DocumentRemover open={openRemover} action={deleteFile} onCancel={() => setOpenRemover(false)} />

        <div className="mt-3 flex w-full items-center space-x-2 justify-between">
            <p>{props.data.fileName}</p>
            <div className="flex items-center space-x-2">
                <Button onClick={download} size="small" className="flex items-center" icon={<FiDownload />}>{t("docPage.tai_xuong")}</Button>
                {isFullPermission && (
                    <>
                        <Button onClick={() => setOpenRemover(true)} size="small" danger className="flex items-center" icon={<FiTrash2 />}>{t("docPage.xoa")}</Button>
                        <Button onClick={() => setOpenShare(true)} type="primary" size="small" className="flex items-center" icon={<FiShare2 />}>{t("docPage.chia_se")}</Button>
                    </>
                )}
            </div>
        </div>

        <div className="mt-3">
            {renderFile()}
        </div>
    </Modal>
}