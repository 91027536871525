import { Button, Form, Input, Modal } from "antd"
import { useTranslation } from "react-i18next"

export default function FolderCreator(props) {

    const { t } = useTranslation()

    return <Modal
        destroyOnClose
        title={t("docPage.createFolder")}
        footer={null}
        { ...props }
    >
        <Form
            onFinish={props.newFolder}
            layout="vertical"
            className="w-full darkness-form"
        >
            <Form.Item
                name="vnFolderName"
                label={t("docPage.viFolderName")}
                rules={[
                    { required: true, message: t("docPage.vui_long_nhap_ten") },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="zhFolderName"
                label={t("docPage.zhFolderName")}
                rules={[
                    { required: true, message: t("docPage.vui_long_nhap_ten") },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="thFolderName"
                label={t("docPage.thFolderName")}
                rules={[
                    { required: true, message: t("docPage.vui_long_nhap_ten") },
                ]}
            >
                <Input />
            </Form.Item>
            <div className="flex space-x-2 items-center">
                <Button htmlType="submit">{t("submit")}</Button>
            </div>
        </Form>
    </Modal>
}