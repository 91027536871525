import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { FcClapperboard, FcPicture } from "react-icons/fc";
import { GrCheckmark } from "react-icons/gr"

export default function SharingFilesFilter({ filterByType, filteringBy }) {

    console.log(`re-render: SharingFilesFilter`)
    const { t } = useTranslation()

    const titleMap = {
        "image": t("image"),
        "video": t("video")
    }

    const items = [
        {
            onClick: () => filterByType("all"),
            label: <div className="flex items-center space-x-1">
                <span>Tất cả</span>
                {!filteringBy && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '1',
        },
        {
            onClick: () => filterByType("image"),
            label: <div className="flex items-center space-x-1">
                <span>Hình ảnh</span>
                {filteringBy === "image" && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '2',
            icon: <FcPicture className="text-xl" />
        },
        {
            onClick: () => filterByType("video"),
            label: <div className="flex items-center space-x-1">
                <span>Video</span>
                {filteringBy === "video" && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '3',
            icon: <FcClapperboard className="text-xl" />
        }
    ];

    const menuProps = {
        items
    };

    return <Dropdown.Button menu={menuProps}>
        { filteringBy ? titleMap[filteringBy] : t("sortFilt.filter_label")}
    </Dropdown.Button>
}