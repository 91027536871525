import { useEffect, useState } from "react";
import { deleteObject, getObjects } from "../../lib/s3storage";
import { Button, List, Modal } from "antd";
import moment from "moment/moment";

export default function FileList() {
    const [listOfFiles, setListOfFiles] = useState([])
    const [openPreview, setOpenPreview] = useState({ open: false, data: undefined })

    useEffect(() => {
        load()
    }, [])

    const load = async () => {
        const { Contents } = await getObjects()
        setListOfFiles(Contents)
        console.log(Contents)
    }

    const delFile = async (Key) => {
        const res = await deleteObject(Key)
        console.log(res)
        await load()
    }

    const item = (data) => <div key={data.ETag} style={{ borderBottom: "solid 1px" }} className="!border-gray-300 py-2 flex justify-between">
        <div>
            <a href={`${process.env.REACT_APP_S3_FILE_URL}/${data.Key}`}>{data.Key}</a>
            <div className="flex text-gray-500 items-center space-x-2">
                <span className="text-xs">{moment(data.LastModified).format("DD/MM/YYYY hh:mm")}</span>
                <span className="text-xs">{(data.Size / 1024 / 1024).toFixed(3)} MB</span>
            </div>
        </div>
        <div className="flex items-center space-x-2">
            <Button type="primary" onClick={() => setOpenPreview({ open: true, data: `${process.env.REACT_APP_S3_FILE_URL}/${data.Key}` })}>Preview</Button>
            <Button onClick={() => window.open(`${process.env.REACT_APP_S3_FILE_URL}/${data.Key}`)}>Download</Button>
            <Button danger onClick={() => delFile(data.Key)} type="primary">Delete</Button>
        </div>
    </div>

    return <div className="">
        <Modal title="Preview" open={openPreview.open} onCancel={()=>setOpenPreview({ open: false, data: undefined })}>
            <video src={openPreview.data} controls className="w-full rounded-md"></video>
        </Modal>
        <span className="text-3xl text-sky-600 font-semibold">S3 Storage Manager</span>
        <List dataSource={listOfFiles} renderItem={item} pagination={{ showSizeChanger: true, defaultPageSize: 10 }}>

        </List>

    </div>
}