import { FiDownload, FiX } from "react-icons/fi";
import { IoMove } from "react-icons/io5";
import {
  useDocumentCtx,
  useFolderCtx,
} from "../../../pages/Documents/Document.Context";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { moveFiles, moveFolder } from "../../../services/document";
import { message } from "antd";

export default function DocumentPickerActions() {
  const { selectedFiles, setSelectedFiles } = useDocumentCtx();
  const { selectedFolderId, setSelectedFolderId } = useFolderCtx();
  const { id } = useParams();

  const download = useCallback(async () => {
    const links = selectedFiles.map((i) => i.filePath);

    links.forEach((url, index) => {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 1000);
    });
  }, [selectedFiles]);

  const move = useCallback(async () => {
    if (selectedFolderId) {
      const response = await moveFolder({
        id: selectedFolderId,
        parentFoldeId: id,
      });
      if (response.success) {
        setTimeout(() => {
          message.success("Chuyển tệp thành công");
        }, 1000);

        window.location.reload();
      }
      return;
    } else {
      const response = await moveFiles({
        folderId: id,
        listFileId: selectedFiles.map((i) => i.id),
      });
      if (response.success) {
        setTimeout(() => {
          message.success("Chuyển tệp thành công");
        }, 1000);

        window.location.reload();
      }
    }
  }, [id]);

  return selectedFiles.length > 0 ? (
    <div
      style={{ border: "solid 1px" }}
      className="!border-gray-300 absolute bg-gray-100 text-gray-600 items-center flex space-x-3 right-0 -bottom-24 py-2 px-6 rounded-md z-50"
    >
      <FiX
        className="text-lg cursor-pointer"
        onClick={() => setSelectedFiles([])}
      />
      <span>Đã chọn {selectedFiles.length} tệp tài liệu</span>

      <FiDownload onClick={download} className="cursor-pointer text-lg" />
      <IoMove onClick={move} className="cursor-pointer text-lg" />
    </div>
  ) : selectedFolderId ? (
    <div
      style={{ border: "solid 1px" }}
      className="!border-gray-300 absolute bg-gray-100 text-gray-600 items-center flex space-x-3 right-0 -bottom-24 py-2 px-6 rounded-md z-50"
    >
      <FiX
        className="text-lg cursor-pointer"
        onClick={() => setSelectedFolderId(null)}
      />
      <span>Đã chọn 1 tệp tài liệu</span>

      <IoMove onClick={move} className="cursor-pointer text-lg" />
    </div>
  ) : (
    <></>
  );
}
