import { Button, Checkbox, List, message, Popconfirm } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ImFileText2 } from "react-icons/im"
import Darkness from "../../lib/darkness";
import { deletePermanently, recover } from "../../services/document";
import Destroyer from "../destroyer";

const TYPE = 1

export default function TrashFileTab({ data }) {
    const [pickedFiles, setPickedFiles] = useState([])
    const [buzz, ctx] = message.useMessage()
    const [openDestroyer, setOpenDestroyer] = useState(false)
    const { t } = useTranslation()

    const onCheck = ({ target: { checked } }, item, isChecked) => {
        if (isChecked) {
            setPickedFiles(prev => [...prev.filter(i => i.id !== item.id)])
        } else { setPickedFiles(prev => [...prev, item]) }
    }

    const onMultiple = ({ target: { checked } }) => {
        if (pickedFiles.length === 0) {
            setPickedFiles([...data.data])
            return
        }
        setPickedFiles([])
    }

    const deleteFiles = async () => {
        const res = await deletePermanently(pickedFiles.map(i => { return { id: i.id, type: i.type } }))
        buzz.info(res.message)
        setPickedFiles([])
        setOpenDestroyer(false)
        Darkness.publish("load_files", { page: 1, pageSize: 20 })
    }

    const multipleSelected = pickedFiles.length > 0

    const onChangePage = (page, pageSize) => {
        Darkness.publish("load_files", { page, pageSize })
    }

    const recoverFiles = async () => {
        const res = await recover(pickedFiles.map(i => { return { id: i.id, type: i.type } }))
        buzz.info(res.message)
        setPickedFiles([])
        Darkness.publish("load_files", { page: 1, pageSize: 20 })
    }

    const recoverSingleFile = async ({ id, type }) => {
        const res = await recover([{ id, type }])
        buzz.info(res.message)
        setPickedFiles([])
        Darkness.publish("load_files", { page: 1, pageSize: 20 })
    }

    const deleteSingleFile = async ({ id, type }) => {
        const res = await deletePermanently([{ id, type }])
        buzz.info(res.message)
        setPickedFiles([])
        Darkness.publish("load_files", { page: 1, pageSize: 20 })
    }

    const File = (detail) => {

        const checked = pickedFiles.find(i => i.id === detail.id)

        return <div style={{ borderBottom: "1px solid" }} className={`group hover:bg-gray-100 !border-gray-200 p-3 flex items-center justify-between`}>
            <div className="left flex items-center space-x-4">
                <Checkbox checked={checked} onChange={e => onCheck(e, detail, checked)} />
                <div className="flex space-x-2 items-center">
                    <ImFileText2 className="text-xl" />
                    <span className="text-gray-600">{detail.name}</span>
                </div>
            </div>
            <div className="hidden group-hover:flex space-x-3">
                <Button onClick={() => recoverSingleFile({ id: detail.id, type: detail.type })} size="small" className="text-green">{t("trang_thung_rac.khoi_phuc")}</Button>
                <Popconfirm
                    title={t("trang_thung_rac.xoa_vinh_vien")}
                    description={t("trang_thung_rac.canh_bao_xoa_vinh_vien")}
                    onConfirm={() => deleteSingleFile({ id: detail.id, type: detail.type })}>
                    <Button size="small" danger className="text-red-500">{t("trang_thung_rac.xoa_vinh_vien")}</Button>
                </Popconfirm>
            </div>
        </div>
    }

    return <div>
        {ctx}
        <Destroyer
            title={t("trang_thung_rac.xoa_vinh_vien")}
            open={openDestroyer}
            action={deleteFiles}
            onCancel={() => setOpenDestroyer(false)}
            warning={t("trang_thung_rac.canh_bao_xoa_vinh_vien")}
        />
        <div style={{ borderBottom: "1px solid" }} className="!border-gray-200 flex px-3 py-2 space-x-4">
            <Checkbox onChange={onMultiple} checked={multipleSelected} />
            <Button disabled={!multipleSelected} onClick={recoverFiles} size="small" className={`${multipleSelected ? "text-gray-600" : "text-gray-300"}`}>{t("trang_thung_rac.khoi_phuc_cac_muc")}</Button>
            <Button disabled={!multipleSelected} onClick={() => setOpenDestroyer(true)} size="small" className={`${multipleSelected ? "text-red-500" : "text-gray-300"}`}>{t("trang_thung_rac.xoa_cac_muc")}</Button>
        </div>
        <List dataSource={data.data} pagination={{ total: data.total, pageSize: 20, onChange: onChangePage, current: data.pageIndex }} renderItem={(i) => <File {...i} />} />
    </div>
}