import { useEffect, useRef } from "react"

// type Props = {
//     url: string
//     seek?: number
//     width?: number
//     height?: number
// } & ComponentProps<"canvas">

export default function Thumbnail(props) {
    // const thumbnail = useRef<HTMLCanvasElement>(null)
    const thumbnail = useRef(null)

    useEffect(() => {
        const video = document.createElement('video')
        video.crossOrigin = 'anonymous'
        const context = thumbnail.current?.getContext('2d')
        video.src = props.src
        video.addEventListener("loadeddata", function () {
            if (thumbnail.current && (thumbnail.current.width && thumbnail.current.height)) {
                video.currentTime = props.seek ?? 1
                video.addEventListener("seeked", () => {
                    context?.drawImage(video, 0, 0, thumbnail?.current?.width ?? 0, thumbnail?.current?.height ?? 0);
                })
            }
        })
    }, [props.src])

    return <>
        <canvas ref={thumbnail} {...props}/>
    </>
}